import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Noindex from "../components/noindex"
import Helmet from "react-helmet"

const AboutPage = (location) => {
	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": "運営者情報",
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	// プロフィールページの構造化データ
	const jsonProfilePage = {
		"@context": "https://schema.org",
		"@type": "ProfilePage",
		"dateCreated": "2017-1-1T0:00:00+09:00",
		"dateModified": "2023-12-11T14:00:00+09:00",		
		"mainEntity": {
			"@type": "Person",
			"name": "オムツ1枚あたりの最安値",
			"alternateName": "hula_la_",
			"interactionStatistic": [{
				"@type": "InteractionCounter",
				"interactionType": "https://schema.org/FollowAction",
				"userInteractionCount": 191
			},{
				"@type": "InteractionCounter",
				"interactionType": "https://schema.org/LikeAction",
				"userInteractionCount": 6
			}],
			"agentInteractionStatistic": {
				"@type": "InteractionCounter",
				"interactionType": "https://schema.org/WriteAction",
				"userInteractionCount": 2410
			},
			"image": "https://pbs.twimg.com/profile_images/1705506131173576704/Rv4yigXE_400x400.jpg",
			"sameAs": [
				"https://x.com/hula_la_",
			]
		}
	};

	return (
		<Layout>
			<div className="section" style={{padding: `0.5rem 1rem`}}>
				<div className="container">
					<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`}}>
						<ul>
							<li><Link to="/">トップ</Link></li>
							<li className="is-active"><Link to="" aria-current="page">運営者情報</Link></li>
						</ul>
					</nav>
					<Helmet>
  	   			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
	   			</Helmet>
					<Helmet>
						<script type="application/ld+json">{JSON.stringify(jsonProfilePage)}</script>
					</Helmet>
					<div className="container">
						<h2 className="title is-5" style={{marginBottom: `0.5rem`}}>当サイトについて</h2>
						<hr style={{margin: `0.5rem 0`}}/>
					</div>
					<div className="content is-small">
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>運営者</h3>
						<p>オムツ1枚あたりの最安値</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>X（旧Twitter）</h3>
						<p><a href="https://x.com/hula_la_">オムツ1枚あたりの最安値(@hula_la_)</a></p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>Instagram</h3>
						<p><a href="https://www.instagram.com/hula_la_i">オムツ1枚あたりの最安値(@hula_la_i)</a></p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>本サイトの目的</h3>
						<p>手間をかけずに安いオムツを見つけることができるように、サポートすることを目的としています。オムツの価格を比較できるように1枚あたりのオムツ価格を算出し、安いオムツを紹介しています。</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>お問い合わせ</h3>
						<p>当サイトは、通販サイトへのリンクを掲載し、商品の紹介のみを行っているサイトです（本サイトでは商品の販売をいたしておりません）。従いまして、通販サイトの商品に関するお問い合わせは、該当商品を販売しているリンク先の販売店までお問い合わせいただけますようお願いいたします。</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>免責事項</h3>
						<p>当サイトの情報は、正確な情報を掲載できるよう努めていますが、当サイトでは一切の保証を致しかねます。正確な情報はリンク先の販売店のWebサイトでご確認ください。</p>
						<h3 className="title is-5" style={{marginBottom: `0.5rem`}}>その他</h3>
						<p>当サイトは、アフィリエイトプログラムによる収益を得ています。また、Amazonのアソシエイトとして、当サイトは適格販売により収入を得ています。</p>
					</div>
				</div>
			</div>
  	  <Seo title="当サイトについて" canonical={location.pathname} />
  	  <Noindex />
		</Layout>			
  )
}

export const query = graphql`
{
	allBrandsYaml {
		edges {
			node {
				id
				description
			}
		}
	}
	allTypesYaml {
		edges {
			node {
				id
				description
			}
		}
	}
	allSizesYaml {
		edges {
			node {
				id
				description
			}
		}
	}
}
`

export default AboutPage
